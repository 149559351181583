import { createApp } from "vue";
import App from "./App.vue";
import { makeApiClient, API_CLIENT_KEY, RegulatorType } from "@/service/api";
import "./style.scss";

async function boostrap() {
  try {
    const root = document.getElementById("signup-widget");

    if (!root) {
      return;
    }

    const regulator = (
      root.dataset.regulator || "cysec"
    ).toLowerCase() as RegulatorType;

    const apiClient = await makeApiClient({
      country: (root.dataset.country || "EE").toUpperCase(),
      language: (root.dataset.language || "en").toLowerCase(),
      apiBaseUrl: process.env.VUE_APP_API,
      regulator: regulator,
    });

    createApp(App)
      .provide(API_CLIENT_KEY, apiClient)
      .use(apiClient.i18n)
      .mount(root);
  } catch (e) {
    console.log(e);
  }
}

if (document.readyState === "loading") {
  // Loading hasn't finished yet
  document.addEventListener("DOMContentLoaded", boostrap);
} else {
  // `DOMContentLoaded` has already fired
  boostrap();
}
