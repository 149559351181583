import axios from "axios";
import { ref, computed, InjectionKey } from "vue";
import { createI18n } from "vue-i18n";
import registration from "./registration";
import { makeLink, LinkEnum, comLanguages } from "./config";

export enum CompanyType {
  AMAS = 2,
  AMUK = 12,
  AMPTY = 9,
  AMCY = 13,
  AMSEY = 15,
  AMJRD = 16,
  AMSAR = 17,
}

export type RegulatorType =
  | "efsa"
  | "fca"
  | "asic"
  | "cysec"
  | "fsa"
  | "jsc"
  | "fsca";

export const companyRegulator = {
  efsa: CompanyType.AMAS,
  fca: CompanyType.AMUK,
  asic: CompanyType.AMPTY,
  cysec: CompanyType.AMCY,
  fsa: CompanyType.AMSEY,
  jsc: CompanyType.AMJRD,
  fsca: CompanyType.AMSAR,
};

export type ApiConfigType = {
  country: string;
  language: string;
  apiBaseUrl: string;
  regulator: RegulatorType;
};

export type Country = {
  id: number;
  code2: string;
  is_legal_available: boolean;
  is_partner_registration_enabled: boolean;
  is_registration_enabled: boolean;
  is_affiliate_registration_enabled: boolean;
  translated_name: string;
};

const loadStyles = new Promise(function (resolve, reject) {
  const head = document.getElementsByTagName("HEAD")[0];
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = `${__webpack_public_path__}css/widget.css`;
  head.appendChild(link);
  link.onload = resolve;
  link.onerror = reject;
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeApiClient = async (config: ApiConfigType) => {
  const country = ref(config.country);
  const regulator = ref<RegulatorType>(config.regulator);
  const language = ref(config.language);
  const countries: { [key: string]: Country } = {};
  const companyId = companyRegulator[regulator.value];

  const i18n = createI18n({
    locale: language.value,
    messages: { [language.value]: {} },
  });
  const assetsInstance = axios.create({
    baseURL: __webpack_public_path__,
  });
  const apiInstance = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
      "Api-Client": "signup_widget",
    },
  });

  const loadMessages = async () => {
    const { data: messages } = await assetsInstance
      .get(`/data/${language.value}.json`, {
        params: { t: new Date().getTime() },
      })
      .catch(() => {
        language.value = "en";
        return assetsInstance.get(`/data/${language.value}.json`, {
          params: { t: new Date().getTime() },
        });
      });

    i18n.global.setLocaleMessage(language.value, messages);
    i18n.global.locale = language.value;

    document.dir = language.value === "ar" ? "rtl" : "ltr";
  };

  const loadCountries = async () => {
    const { data } = await apiInstance
      .get<Country[]>("/countries/active/", {
        params: {
          company_id: companyId,
        },
      })
      .catch(() => {
        return {
          data: [
            {
              id: 69,
              name: "Estonia",
              code2: "EE",
              is_legal_available: true,
              is_partner_registration_enabled: true,
              is_registration_enabled: false,
              is_affiliate_registration_enabled: true,
              translated_name: "Estonia",
            },
          ],
        };
      });

    data.forEach((item) => {
      countries[item.code2] = item;
    });

    if (!countries[country.value]) {
      country.value = Object.values(countries)[0].code2;
    }
  };

  await Promise.all([loadMessages(), loadCountries(), loadStyles]);

  const countryId = computed(() =>
    countries[country.value] ? countries[country.value].id : 0
  );

  const signupCheckbox = computed(
    () => companyId === CompanyType.AMCY || companyId === CompanyType.AMUK
  );

  const getLink = (linkName: LinkEnum): string => {
    const homePage = "https://admiralmarkets.com";
    const linkLanguage = comLanguages.includes(language.value)
      ? language.value
      : "en";

    return makeLink({
      homePage,
      regulator: regulator.value,
      linkName,
      language: linkLanguage,
    });
  };

  const replaceLinks = (
    text: string,
    links: { [key: string]: LinkEnum }
  ): string => {
    let readyText = text;

    Object.keys(links).map((linkName) => {
      const link = getLink(links[linkName]);

      readyText = readyText
        .replace(
          `[${linkName}]`,
          `<a target="_blank" rel="nofollow" href="${link}">`
        )
        .replace(`[/${linkName}]`, "</a>")
        .replace(`[${linkName}]`, "</a>");
    });

    return readyText;
  };

  return {
    config,
    i18n,
    language,
    companyId,
    country,
    countryId,
    countries,
    registration: registration(apiInstance),
    getLink,
    replaceLinks,
    signupCheckbox,
  };
};

type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: never) => Promise<infer U>
  ? U
  : T extends (...args: never) => infer U
  ? U
  : T;

export const API_CLIENT_KEY: InjectionKey<Unwrap<typeof makeApiClient>> =
  Symbol("API_CLIENT_KEY");
