import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eada9d92"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass({
      'signup-widget__checkbox': true,
      'signup-widget__checkbox--error': _ctx.error,
      'signup-widget__checkbox--light': _ctx.light,
    })
  }, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      type: "checkbox",
      class: "signup-widget__checkbox-input"
    }, null, 8, _hoisted_1), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}