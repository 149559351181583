
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  name: "CheckboxField",
  emits: ["update:modelValue"],
  props: {
    id: {
      type: String as PropType<string>,
      required: true,
    },
    error: {
      type: Boolean as PropType<boolean>,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(nextValue) {
        emit("update:modelValue", nextValue);
      },
    });

    return { value };
  },
});
