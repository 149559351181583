
import { computed, defineComponent, inject, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { email, required, minLength } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import {
  AdmButton,
  AdmCard,
  AdmSelect,
  AdmTypography,
  countryPhones,
  EmailField,
  PasswordField,
  PhoneField,
  MessageBox,
  InfoBox,
  CheckboxField,
  languages,
} from "@/components";
import { API_CLIENT_KEY, CompanyType } from "@/service/api";
import gtm from "@/service/gtm";
import { LinkEnum } from "@/service/config";

export default defineComponent({
  name: "FirstStep",
  emits: ["success"],
  components: {
    AdmTypography,
    AdmCard,
    AdmSelect,
    PhoneField,
    EmailField,
    PasswordField,
    AdmButton,
    MessageBox,
    InfoBox,
    CheckboxField,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const apiClient = inject(API_CLIENT_KEY);
    const apiErrors = ref<{ [key: string]: string[] }>({});
    const {
      country,
      countryId,
      countries,
      companyId,
      registration,
      signupCheckbox,
      language,
      getLink,
      replaceLinks,
    } =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      apiClient!;

    const countryPhone = countryPhones.find(
      ({ value }) => value === country.value
    );
    const countryOptions = Object.keys(countries).map((value) => ({
      label: countries[value].translated_name,
      value,
    }));

    const loading = ref(false);

    const disabled = computed(() => {
      return loading.value;
    });

    const state = reactive({
      phone: "",
      code: countryPhone ? countryPhone.code : "+1",
      email: "",
      password: "",
      marketing_emails_enabled: false,
    });

    const passwordHints = computed(() => {
      const rules: Record<string, { rule: RegExp; label: string }> = {
        uppercase: {
          rule: new RegExp(/[A-Z]/),
          label: t(
            "2-stepSignUpForm.all-step-2-signup-password-rule-message-1"
          ),
        },
        lowercase: {
          rule: new RegExp(/[a-z]/),
          label: t(
            "2-stepSignUpForm.all-step-2-signup-password-rule-message-2"
          ),
        },
        number: {
          rule: new RegExp(/\d/),
          label: t(
            "2-stepSignUpForm.all-step-2-signup-password-rule-message-3"
          ),
        },
        specialChar: {
          rule: new RegExp(/[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/),
          label: t(
            "2-stepSignUpForm.all-step-2-signup-password-rule-message-4"
          ),
        },
        minLength: {
          rule: new RegExp(/^.{8,128}$/),
          label: t(
            "2-stepSignUpForm.all-step-2-signup-password-rule-message-5"
          ),
        },
        latinOnly: {
          // eslint-disable-next-line no-control-regex
          rule: new RegExp(/^[\x00-\x7F]+$/),
          label: t("2-stepSignUpForm.all-step-2-signup-password-field-error-7"),
        },
      };

      return Object.keys(rules).map((key) => {
        return {
          label: rules[key].label,
          valid: rules[key].rule.test(state.password),
        };
      });
    });

    const isValidPassword = () =>
      passwordHints.value.findIndex(({ valid }) => !valid) === -1;

    const validation = useVuelidate(
      {
        phone: { required, minLength: minLength(5) },
        code: { required },
        email: { required, email },
        password: { isValidPassword },
      },
      state
    );

    const locales: Record<
      string,
      { label: string; iso: string; locale: string }
    > = languages;

    const register = () => {
      loading.value = true;
      apiErrors.value = {};

      registration
        .unified({
          marketing_emails_enabled: signupCheckbox.value
            ? state.marketing_emails_enabled
            : true,
          country_id: countryId.value,
          company_id: companyId,
          entity: state.email,
          registration_type: "email",
          phone_number: `${state.code}${state.phone}`,
          password: state.password,
          domain_alias: "com",
          _lang: locales[language.value].iso || "en-US",
        })
        .then(({ data }) => {
          gtm.pushData({ event: "UserID", UserID: data.profile_id });
          gtm.pushData({ event: "Registration Success" });
          emit("success", data);
        })
        .catch(({ response }) => {
          apiErrors.value = response.data.fields_codes || {
            general_error: true,
          };
          loading.value = false;
        });
    };

    const handleSubmit = async () => {
      validation.value.$touch();

      if (validation.value.$invalid) {
        return;
      }
      await register();
    };

    const signupWarning = computed(() => {
      return t("2-stepSignUpForm.signup-main-warning", {
        privacyPolicyLink: getLink(LinkEnum.privacyPolicy),
        contactUsLink: getLink(LinkEnum.contact),
        subscriptionsLink: getLink(LinkEnum.login),
      });
    });

    const formError = computed(() => {
      const errors: Record<string, string> = {
        email_used: replaceLinks(
          t("2-stepSignUpForm.all-step-signup-email-used-error"),
          {
            link: LinkEnum.login,
          }
        ),
        phone_number_used: replaceLinks(
          t("2-stepSignUpForm.all-step-signup-phone-used-error"),
          {
            link: LinkEnum.login,
          }
        ),
        general_error: t("2-stepSignUpForm.all-step-signup-general-error-1"),
      };

      let errorKey = "";

      if (apiErrors.value.entity && apiErrors.value.entity.length) {
        errorKey = apiErrors.value.entity[0];
      }

      if (apiErrors.value.phone_number && apiErrors.value.phone_number.length) {
        errorKey = apiErrors.value.phone_number[0];
      }

      if (apiErrors.value.general_error) {
        errorKey = "general_error";
      }

      return errors[errorKey] || null;
    });

    const onlyDemo = computed(() => {
      return [110, 39, 229].indexOf(countryId.value) > -1;
    });

    const privacyPolicyMessage = computed(() =>
      replaceLinks(
        t("2-stepSignUpForm.all-step-signup-personal-data-warning"),
        {
          link: LinkEnum.privacyPolicy,
        }
      )
    );

    const privacyPolicyMessageNew = computed(() =>
      replaceLinks(t("2-stepSignUpForm.signup-main-new-warning"), {
        link: LinkEnum.privacyPolicy,
      })
    );

    const companyName = computed(() => {
      return CompanyType.AMUK === companyId ? "Admiral Markets" : "Admirals";
    });

    const companyJRDorCY = computed(
      () => companyId === CompanyType.AMCY || companyId === CompanyType.AMJRD
    );

    return {
      country,
      countryOptions,
      validation,
      state,
      handleSubmit,
      apiErrors,
      formError,
      loading,
      signupWarning,
      passwordHints,
      disabled,
      onlyDemo,
      signupCheckbox,
      register,
      companyJRDorCY,
      privacyPolicyMessage,
      privacyPolicyMessageNew,
      companyName,
    };
  },
});
